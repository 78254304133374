import mainjson from '../config/main';
export default function GetData() {
        return {
        "apitype":"donations",
        "id":"donation_id",
        "subidname":"donation_event_id",
        "options":["view"],
        "nameSingle":"donation",
        "nameMultiple":"donations",
        "filters":
        [
            {
                "key":"state",
                "name":"State",
                "options":{
                    "paid":"Paid",
                    "notpaid":"Not paid"
                }
            }
        ]
    ,
        "fields":{
            "field1":{
                "name":"User id",
                "field":"donation_user_id",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Amount",
                "field":"donation_amount",
                "type":"TextInput",
                "required":true,
                "list":true

            },
            "field3":{
                "name":"Date",
                "field":"donation_insert_date",
                "type":"DateTimeInput",
                "required":true,
                "list":true,
                "justshow":true
            },

        }
    }
}